.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.add_search_div {
  display: flex;
  align-items: center;
}
