#example1_wrapper{
    margin-top: 21px;
}
.page_size{
    width: 100%;
    height: 100%;
}
.plot_flex_container,
.forward-ref {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.childer {
    width: 150px;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    margin: 10px;
    border: 1px solid red;
    background: #fff;
  }

.sortable-chosen {
    background-color: blue;
  }
  
  .sortable-ghost {
    background-color: #fff;
  }

  .mainContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;

    .subContainer {
      // display: flex;
      // display: inline;
      // margin: 5px 10px;
    }
  }

  .programTable {
    margin: 20px 20px;
    font-size: 1.05em;
    /* text-align: center; */
    /* min-width: 400px; */
    border-radius: 5px 5px 0px 0px;
    // overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 15);
    width: 85%;
    text-align: center;
    
    thead {
      background-color: #009879;
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
    tbody {
      tr:nth-child(even) {background-color: #d4d5f7;}
    }
  }
  .programDiv {
    display: flex;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
  }
