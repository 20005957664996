@import "rsuite/dist/rsuite.css";

#plot_1{
    margin: 10px 10px;
    width:600px; /* or whatever width you want. */
    max-width:600px; /* or whatever width you want. */
    resize: both;
    // overflow: auto;
}

.bottom_plot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    align-self: center;
    border: 2px solid rgb(17, 124, 154);
    border-radius: 10px;
    margin: 3px 3px;
    padding:  3px 3px;
    background-color: aliceblue;
    max-width: 600px;
    text-align: center;
    z-index: 1;
    margin-top: -90px;
}

/* Re-order items into rows */
// .item:nth-child(3n+1) { order: 1; }
// .item:nth-child(3n+2) { order: 2; }
// .item:nth-child(3n)   { order: 3; }
.item {
    height: auto;
    display: inline-block;
    align-self: center;
}
/* Force new columns */
// .mainContainer::before,
// .mainContainer::after {
//   content: "";
//   flex-basis: 100%;
//   width: 0;
//   order: 2;
// }
.mainContainer {
    align-items: flex-start;
    justify-content: center;
}

.plot_container {
    // background-color: gray
    border-radius: 10px;
}
#xTitle_val {
    transform: translateY(-20px);
}
#yTitle_val {
    // transform: translateX(-5px);
}
.datepicker{
    // text-align: center;
    position: relative;
}
.form-check {
    padding-top: 3px;
}
.datepicker1{
    position: relative;
    margin-top: -36px;
    margin-left: 270px;
}
.popover {
    z-index: -1;
}

.bottom_plot_elements {
    // margin-right: 10px;
}

.rs-anim-fade{
    // margin-bottom: 10px;
}
.rs-picker-daterange-menu {
    // z-index: 10000000000; /*added a random number*/
    // margin-bottom: 50px;
    // position: relative;
  }
  .rs-picker-default .rs-picker-toggle{
    // z-index: 10000000000; /*added a random number*/
    // position: relative;
  }
  .rs-picker-default {
    // position: relative;
    // margin-bottom: 50px;
  }

  #accordionExample{

  }
  .options_plot {
    display: flex;
    justify-content: end;
    margin-right: 5px;
    // height: 20px;
    // font-size: 12px;
    margin-top: -37px;
  }
  .options_button {
    // align-items: flex-end;
    // display: flex;
  }

  #legend_plot_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 600px;
    margin: 0px 5px;
    margin-top: 15px;
  }
  .legend {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 5px;
  }
    #legend_box_1 {
        cursor: pointer;
        width: 40px;
        background-color: red;
        color: red;
        margin-right: 7px;
        border-radius: 8px;
        #legend_text_1 {
            margin-right: 7px;
            font-weight: 600;
        }
    }
    #legend_box_2 {
        cursor: pointer;
        width: 40px;
        background-color: white;
        color: white;
        margin-right: 7px;
        border-radius: 8px;
        #legend_text_2 {
            margin-right: 7px;
            font-weight: 600;
        }
    }
    #legend_box_3 {
        cursor: pointer;
        width: 40px;
        background-color: orange;
        color: orange;
        margin-right: 7px;
        border-radius: 8px;
        #legend_text_3 {
            margin-right: 7px;
            font-weight: 600;
        }
    }
    #legend_box_4 {
        cursor: pointer;
        width: 40px;
        background-color: blue;
        color: blue;
        margin-right: 7px;
        border-radius: 8px;
        #legend_text_4 {
            margin-right: 7px;
            font-weight: 600;
        }
    }


