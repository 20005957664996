#example {
    margin:10px 10px;
}

#example_length {
    margin-left: 10px;
    margin-right: 10px;
}
#example_filter {
    margin-right: 10px;
}
#example_info {
    margin-left: 10px;
}
#example_paginate {
    margin-right: 10px;
}